import React from 'react';
import "./about.css"
import { ArrowRightIcon } from '@heroicons/react/24/outline'

import image from '../../asset/research.png';


const Research = () => {
  return (
    <div className='bg-pattern'>
      <div className="lg:grid block py-5 px-6  lg:px-6 aboutInfoContainer lg:grid-cols-2 items-center justify-center gap-24 lg:py-24">
        {/* Main Body */}
            
          
            {/* Info Section */}
            <div className="lg:col-span-1 px-[40px] py-5" >
              <h1 className="lg:text-[2.2rem] text-[1.8rem] bg-gradient-to-r from-[#99821d] via-[#ccc595] to-[#9d8944] inline-block text-transparent bg-clip-text uppercase lg:leading-[2.5rem] font-[GilroyBold]">DESIGN, RESEARCH AND DEVELOPMENT</h1>
              <p className="text-black my-1 text-[1.4rem] font-[GilroyMedium] lg:my-6 aboutContent ">
              <strong>HARDCOATS (INDIA) PVT. LTD.</strong>  our commitment to innovation and precision extends beyond manufacturing to encompass a robust Design, Research, and Development (R&D) division. Utilizing cutting-edge software tools such as SolidWorks, AutoCAD, and Siemens NX, our talented team of engineers and designers collaborates to bring our clients' visions to life. Our R&D team is dedicated to exploring new technologies and materials, ensuring that we remain at the forefront of industry advancements. By leveraging advanced simulations and modeling techniques, we optimize designs for performance, durability, and manufacturability, tailored specifically to the unique requirements of the Automotive, Power, Energy, Packaging, and Oil & Gas sectors.
              </p>
              
              <p className="text-black my-1 text-[1.4rem] font-[GilroyMedium] lg:my-6 aboutContent ">
              We take pride in having developed and manufactured a wide range of high- quality products, including mechanical seals, hydraulic fittings, valves, drive mechanisms and high-precision rotary shafts. From initial concept development to final product realization, we emphasize iterative testing and refinement, allowing us to identify and mitigate potential challenges early in the design process. This approach not only enhances the quality and precision of our products but also significantly reduces lead times and costs for our clients.              
              </p>
              
              <p className="text-black text-[1.4rem] my-1 font-[GilroyMedium] lg:my-6 aboutContent ">
              Whether developing high-precision components or complex assemblies, our R&D division is committed to delivering innovative solutions that meet exacting standards and exceed client expectations. Together, we transform ideas into reality, ensuring that every product we create is built on a foundation of excellence and technical expertise.
              </p>

              <button className='bg-gradient-to-r from-[#99821d]  to-[#9d8944] text-white px-6 flex items-center justify-center gap-4 py-3 mt-3 rounded-lg text-[1.1rem]'>Know More <ArrowRightIcon className='w-[25px]' /></button>
            </div>
            

            <div className="lg:col-span-1 flex items-center justify-center px-[40px] py-5">
            {/*  Images */}
              <img className='2xl:w-[450px]' src={image} alt="Image 1"/>
             
            </div>


      </div>
    </div>
  );
};

export default Research;
