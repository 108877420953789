import React from 'react'
import "./Career.css"

const CareerInfo = () => {
  return (
    <div className='bg-pattern'>
      {/* Banner Section */}
      <div className="w-[100%] py-8  " >
        
      

      {/* Current Openings Section */}
      <section className="py-12 px-4 sm:px-6 lg:px-8">
        <h2 className="text-3xl text-center font-semibold mb-8">Current Openings</h2>
        <div className="overflow-x-auto">
          <table className="w-[80%] mx-auto bg-white border shadow-xl rounded-lg overflow-hidden border-gray-300">
            <thead className='bg-gray-300'>
              <tr>
                <th className="py-2 px-4 text-left border-b">Job Title</th>
                <th className="py-2 px-4 text-left border-b">Experience</th>
                <th className="py-2 px-4 text-left border-b">Apply Now</th>
              </tr>
            </thead>
            <tbody className='bg-gray-50' >
              {/* Example Row 1 */}
              <tr>
                <td className="py-2 px-4 border-b">Frontend Developer</td>
                <td className="py-2 px-4 border-b">2-3 Years</td>
                <td className="py-2 px-4 border-b">
                  <button className="bg-blue-500 text-white py-1 px-4 rounded hover:bg-blue-600">Apply</button>
                </td>
              </tr>
              {/* Example Row 2 */}
              <tr>
                <td className="py-2 px-4 border-b">Backend Developer</td>
                <td className="py-2 px-4 border-b">3-5 Years</td>
                <td className="py-2 px-4 border-b">
                  <button className="bg-blue-500 text-white py-1 px-4 rounded hover:bg-blue-600">Apply</button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </section>

      {/* Career Form Section */}
      <section className="py-12 px-4 lg:px-8 ">
        <h2 className="text-3xl text-center font-semibold mb-6">Apply for a Position</h2>
        <div className="w-[80%] mx-auto">
          <form className="bg-white p-6 rounded shadow-lg space-y-4 ">
            <div className='grid grid-cols-1 md:grid-cols-2 gap-4'>
                {/* Name Field */}
                <div>
                <label className="block text-sm font-medium mb-1 ">Name</label>
                <input
                    type="text"
                    className="w-full px-3 py-2 border rounded focus:outline-none focus:ring focus:ring-blue-300"
                    placeholder="Your Name"
                />
                </div>

                {/* Email Field */}
                <div>
                <label className="block text-sm font-medium mb-1">Email</label>
                <input
                    type="email"
                    className="w-full px-3 py-2 border rounded focus:outline-none focus:ring focus:ring-blue-300"
                    placeholder="Your Email"
                />
                </div>
            </div>
            {/* Current Position Field */}
            <div>
              <label className="block text-sm font-medium mb-1">Current Position</label>
              <input
                type="text"
                className="w-full px-3 py-2 border rounded focus:outline-none focus:ring focus:ring-blue-300"
                placeholder="Your Current Position"
              />
            </div>

            {/* Current Company Name Field */}
            <div>
              <label className="block text-sm font-medium mb-1">Current Company Name</label>
              <input
                type="text"
                className="w-full px-3 py-2 border rounded focus:outline-none focus:ring focus:ring-blue-300"
                placeholder="Current Company Name"
              />
            </div>

            {/* Cover Letter Field */}
            <div>
              <label className="block text-sm font-medium mb-1">Cover Letter</label>
              <textarea
                className="w-full px-3 py-2 border rounded focus:outline-none focus:ring focus:ring-blue-300"
                placeholder="Your Cover Letter"
                rows="4"
              />
            </div>

            {/* Submit Button */}
            <div className="text-center">
              <button className="bg-blue-500 text-white py-2 px-6 rounded hover:bg-blue-600">
                Submit
              </button>
            </div>
          </form>
        </div>
      </section>
    </div>

    </div>
  )
}

export default CareerInfo