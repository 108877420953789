import React from 'react'
import { NavbarTwo } from '../components/Layout/Navbar'
import Banner from '../components/Home/Banner'
import AboutHome from '../components/Home/AboutHome'
import Testimonial from '../components/Home/Testimonial'
import Industries from '../components/Home/Industries'
import Numbers from '../components/Home/Numbers'
import Clients from '../components/Home/Clients'
import Footer from '../components/Layout/Footer'

const Home = () => {
  return (
    <div>
        <NavbarTwo />
        <div className='2xl:pt-[120px] pt-[80px] xl:pt-[110px] lg:pt-[100px]'>
        <Banner />
        <AboutHome />
        <Testimonial />
        <Industries />
        <Numbers />
        <Clients />
        <Footer />
        </div>
    </div>
  )
}

export default Home