import React from 'react';

const IndustryCard = ({ image, title }) => {
  return (
    <div className='rounded-t-lg p-4'>
      <div className='flex justify-center'>
        <img className=' rounded-full object-cover industryCardImg'  src={image} alt={title} />
      </div>
      <h6 className='lg:my-4 my-2 py-1 font-bold flex text-center items-center justify-center gap-2 text-[.9rem]'>
        <span className='w-[4px] h-[30px] block '></span>
        {title}
      </h6>
    </div>
  );
};

export default IndustryCard;
