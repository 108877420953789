import React from 'react'
import image10 from '../../asset/image10.png'
import "./about.css"
import { ArrowRightIcon } from '@heroicons/react/24/outline'
import { motion } from 'framer-motion';

const Quality = () => {
  return (
    <div className='bg-pattern'>
      <div className="lg:grid block py-5 px-6  lg:px-6 aboutInfoContainer grid-cols-2 items-center justify-center gap-24 lg:py-24">
      {/* Main Body */}
            
          
            {/* Info Section */}
        <div className="lg:col-span-1 px-[40px] py-4">
            <h1 className="lg:text-[2.2rem] text-[1.8rem] bg-gradient-to-r from-[#99821d] via-[#ccc595] to-[#9d8944] inline-block text-transparent bg-clip-text uppercase lg:leading-[2.5rem] font-[GilroyBold]">QUALITY</h1> 
            <p className="text-black my-1 text-[1.4rem] font-[GilroyMedium] lg:my-6 aboutContent ">
            We employ rigorous testing and quality control measures at every production stage to meet our customers' exact specifications. Using advanced equipment like coordinate measuring machines and non-destructive testing methods ensures precision and reliability. Our experienced quality personnel oversee these procedures to deliver superior products that pass all validations.
            </p>
            <p className="text-black my-1 text-[1.4rem] font-[GilroyMedium] lg:my-6 aboutContent ">
            Our facilities are outfitted with the necessary machinery for conducting dimensional and geometric inspections, ensuring precise adherence to specifications set by our clients down to the micron level. Our manufacturing unit has accreditation for ISO 9001:2015 certification for quality management systems.
            </p>

            <div className="mb-8">
                <h2 className="text-2xl font-semibold text-black mb-4">
                Quality Control Equipment
                </h2>
                <ul className="list-disc list-inside space-y-2 text-black">
                <li>CMM (500 X 600 X 400)</li>
                <li>Surface Tester</li>
                <li>Profile Projector</li>
                <li>Hardness Tester</li>
                <li>Electronic Height Gauge</li>
                </ul>
            </div>

        {/* Facilities Section */}
        <div>
            <h2 className="text-2xl font-semibold text-black mb-4">
            Quality Control Facilities
            </h2>
            <ul className="list-disc list-inside space-y-2 text-black">
            <li>NDT facilities as follows:
                <ul className="list-disc list-inside ml-8 space-y-1">
                <li>Ultrasonic</li>
                <li>Magnetic Particle</li>
                <li>Dye Penetrant</li>
                </ul>
            </li>
            <li>Experienced personnel for Procedures and QA Plans</li>
            <li>Fully equipped with facilities for Dimensional & Geometrical Inspection</li>
            </ul>
        </div>
        <button className='bg-gradient-to-r from-[#99821d]  to-[#9d8944] text-white px-6 flex items-center justify-center gap-4 py-3 mt-3 rounded-lg text-[1.1rem]'>Know More <ArrowRightIcon className='w-[25px]' /></button>

        </div>

            <div className="lg:col-span-1 flex items-center justify-center px-[40px] py-5">
            {/*  Images */}
              <img className='2xl:w-[500px]' src={image10} alt="Image 1"/>
             
            </div>

      </div>
    </div>
  )
}

export default Quality