import React from 'react'
import "./Contact.css"
const ContactBreadcrub = () => {
  return (
    <div className='contactBreadcrubContainer '>
            <h1 className='lg:text-[3.5rem] text-[2.2rem] text-white inline-block text-transparent bg-clip-text uppercase font-[700]'>Contact Us</h1>
    </div>
  )
}

export default ContactBreadcrub;