import { ArrowRightIcon } from '@heroicons/react/24/outline'
import React from 'react'
import AboutMainImage from "../../asset/aboutImage.png"
import "./Home.css"
import {motion} from "framer-motion"
const AboutHome = () => {
  return (
    <div className='lg:flex block py-8 px-2  lg:px-6 aboutHomeContainer  aboutBg items-center justify-center gap-24 lg:py-24 ' >
        <motion.div initial={{ opacity: 0, scale: 0 }}
					whileInView={{ opacity: 1, scale: 1 }}
         
					transition={{ type: "spring", duration:1  }}
					viewport={{ once: true }} className='lg:w-[50%]'>
         <h5 className='lg:text-[1.2rem] text-[1rem] font-[GilroyMedium] text-gray-600 '>Welcome to Hardcoats (India) Pvt.Ltd. </h5>
       <h1 className='lg:text-[2.2rem] text-[1.2rem] bg-gradient-to-r from-[#99821d] via-[#ccc595] to-[#9d8944] inline-block text-transparent bg-clip-text uppercase lg:leading-[2.5rem] font-[GilroyBold]'>Our commitment to excellence extends to the following sectors       </h1>
       {/* <div className='my-6'>
        <p className='text-[1.1rem] font-[600]'>
          <p className='flex items-center justify-start gap-2'><span className='w-[40px] h-[5px] rounded-lg content-none block  bg-gradient-to-r from-[#99821d] via-[#ccc595] to-[#9d8944]'></span> Energy Sector</p> 
          <p className='flex items-center justify-start gap-2' > <span className='w-[40px] h-[5px] rounded-lg content-none block  bg-gradient-to-r from-[#99821d] via-[#ccc595] to-[#9d8944]'></span>Power Sector</p>
          <p className='flex items-center justify-start gap-2'><span className='w-[40px] h-[5px] rounded-lg content-none block  bg-gradient-to-r from-[#99821d] via-[#ccc595] to-[#9d8944]'></span> Automotive Industry</p>
           <p className='flex items-center justify-start gap-2'><span className='w-[40px] h-[5px] rounded-lg content-none block  bg-gradient-to-r from-[#99821d] via-[#ccc595] to-[#9d8944]'></span> Oil & Gas Industry</p>
           <p className='flex items-center justify-start gap-2'><span className='w-[40px] h-[5px] rounded-lg content-none block  bg-gradient-to-r from-[#99821d] via-[#ccc595] to-[#9d8944]'></span> Packing Industry</p>
            </p>
       </div> */}
       <p className='text-gray-600 my-2 font-[GilroyMedium] lg:my-6 aboutContent '><strong> We HARDCOATS (INDIA) PVT. LTD </strong> are a diversified manufacturer and supplier of high precision
            components and assemblies, catering to a global clientele in the Automotive industries, Power Sector,
            Energy Sector, Packaging Industry and Oil & Gas Industries since 1990. <br />
            We are well equipped with high precision conventional and CNC - VMC machineries, knowledgeable
            technical team, highly skilled workforce and experienced Quality - Control Assurance and planning
            Department. Despite the small size of our company, with teamwork and dynamic leadership, we are in
            position to execute any high precision engineering products at exacting standards required in Energy,
            Power and other industries.
        </p>
       <button className='bg-gradient-to-r from-[#99821d]  to-[#9d8944] text-white px-6 flex items-center justify-center gap-4 py-3 mt-3 rounded-lg text-[1.1rem]'>Know More <ArrowRightIcon className='w-[25px]' /></button>
        </motion.div>
        <div className='lg:w-[50%]'>
      <motion.img initial={{ opacity: 0, scale: 0 }}
					whileInView={{ opacity: 1, scale: 1 }}
					transition={{ duration: 0.6, type: "spring" }}
					viewport={{ once: true }} src={AboutMainImage} />
        </div>
    </div>
  )
}

export default AboutHome