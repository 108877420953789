import React from 'react'
import ClientLogoOne from "../../asset/client1.png"
import ClientLogoTwo from "../../asset/client2.png"
import ClientLogoThree from "../../asset/client3.png"
import ClientLogoFour from "../../asset/client4.png"
import ClientLogoFive from "../../asset/client5.png"
import ClientLogoSix from "../../asset/client6.png"
import ClientLogoSeven from "../../asset/client7.png"
import ClientLogoEight from "../../asset/client8.png"
import ClientLogoNine from "../../asset/client9.png"
import ClientLogoTen from "../../asset/client10.png"
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, {
  Navigation,
  Pagination,
  Scrollbar,
  A11y,
  Autoplay,
} from "swiper/modules";
// Import Swiper styles
import "swiper/css";
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
const Clients = () => {
  return (
    <div className='clientbox bg-white'>
     <div className='text-center'>
            <h3 className='lg:text-[1.1rem] text-[.8rem] text-gray-600'>People We Have Worked With</h3>
            <h2 className='lg:text-[3.5rem] text-[2.2rem] bg-gradient-to-r from-[#99821d] via-[#ccc595] to-[#9d8944] inline-block text-transparent bg-clip-text uppercase font-[700]'>Our Clientele</h2>
        </div>
    <div className=' lg:grid hidden 2xl:grid-cols-5 lg:grid-cols-5 mt-12 items-center justify-center grid-flow-row gap-y-20 gap-x-6'>
       
        <div className='clientLogoBox '>
            <img src={ClientLogoOne} />
        </div>
        <div className='clientLogoBox ' >
            <img src={ClientLogoTwo} />
        </div>
        <div className='clientLogoBox ' >
            <img src={ClientLogoThree} />
        </div>
        <div className='clientLogoBox' >
            <img src={ClientLogoFour} />
        </div>
        <div className='clientLogoBox' >
            <img src={ClientLogoFive} />
        </div>
        <div className='clientLogoBox ' >
            <img src={ClientLogoSix} />
        </div>
        <div className='clientLogoBox' >
            <img src={ClientLogoSeven} />
        </div>
        <div className='clientLogoBox' >
            <img src={ClientLogoEight} />
        </div>
        <div className='clientLogoBox' >
            <img src={ClientLogoNine} />
        </div>
        <div className='clientLogoBox' >
            <img src={ClientLogoTen} />
        </div>
    </div>
    <div className='lg:hidden block'>
    <Swiper
        
          spaceBetween={0}
          className="mt-3  lg:mt-0 items-center justify-center"
         
          breakpoints={{
        576: {
          width: 576,
          slidesPerView: 1,
        },
        780: {
          width: 780,
          slidesPerView: 2,
        },
        1100: {
          width: 1100,
          slidesPerView: 2.5,
        },
         1440:{
          width:1440,
          slidesPerView:2.3
        },
        1800:{
          width:1800,
          slidesPerView:2.3
        }
      }}
     
          loop={true}
          autoplay={{
            delay: 1000,
            disableOnInteraction: false,
          }}
          
         
          modules={[Navigation,Autoplay]}
          onSlideChange={() => console.log("slide change")}
          onSwiper={(swiper) => console.log(swiper)}
        > 
        <SwiperSlide>
        <div className='px-12 py-4 ' >
            <img src={ClientLogoOne} />
        </div>
        </SwiperSlide>
        <SwiperSlide>
        <div className='px-12 py-4 ' >
            <img src={ClientLogoOne} />
         
        </div>
        </SwiperSlide>
        <SwiperSlide>
        <div className='px-12 py-4 ' >
            <img src={ClientLogoOne} />
        </div>
        </SwiperSlide>
        <SwiperSlide>
        <div className='px-12 py-4 ' >
            <img src={ClientLogoOne} />
        </div>
        </SwiperSlide>
        <SwiperSlide>
        <div className='px-12 py-4 ' >
            <img src={ClientLogoOne} />
        </div>
        </SwiperSlide>
        </Swiper>
    </div>
    </div>
  )
}

export default Clients