import React from 'react'
import { NavbarTwo } from '../components/Layout/Navbar'
import Quality from '../components/About/Quality'
import Footer from '../components/Layout/Footer'

const Qualitypage = () => {
  return (
    <div>
        <NavbarTwo />
        <div className='2xl:pt-[120px] pt-[80px] xl:pt-[110px] lg:pt-[100px]'>
        <Quality />
        <Footer />
        </div>
    </div>
  )
}

export default Qualitypage