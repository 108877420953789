import React from 'react'
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, {
  Navigation,
  Pagination,
  Scrollbar,
  A11y,
  Autoplay,
} from "swiper/modules";
// Import Swiper styles
import "swiper/css";
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import SlideOne from "../../asset/slideOne.webp"
import SlideTwo from "../../asset/slideTwo.webp"
import SlideThree from "../../asset/slideThree.webp"
const Banner = () => {
  return (
    <div className=' '>
        <Swiper
      spaceBetween={0}
      slidesPerView={1}
    className='z-[800]'
      loop={true}
          
          
          modules={[Pagination,Autoplay]}
          autoplay={true}
          onSlideChange={() => console.log("slide change")}
          onSwiper={(swiper) => console.log(swiper)}
    >
      <SwiperSlide>
        <div>
            <img src={SlideOne} />
        </div>
      </SwiperSlide>
      <SwiperSlide>
        <div>
            <img src={SlideTwo} />
        </div>
      </SwiperSlide>
      <SwiperSlide>
        <div>
            <img src={SlideThree} />
        </div>
      </SwiperSlide>
      
    
    </Swiper>
    </div>
  )
}

export default Banner