import React from 'react'
import { NavbarTwo } from '../components/Layout/Navbar'
import Footer from '../components/Layout/Footer'
import AboutInfo from '../components/About/AboutInfo'

const Aboutus = () => {
  return (
    <div>
        <NavbarTwo />
        <div className='2xl:pt-[120px] pt-[80px] xl:pt-[110px] lg:pt-[100px]'>
        <AboutInfo />
        <Footer />
        </div>
    </div>
  )
}

export default Aboutus;