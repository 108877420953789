import React from "react";
import {
  Navbar,
  Collapse,
  Typography,
  IconButton,
  List,
  ListItem,
  Menu,
  MenuHandler,
  MenuList,
  MenuItem,
  MobileNav
} from "@material-tailwind/react";
import {
  ChevronDownIcon,
  Bars3Icon,
  XMarkIcon,
  ChevronUpIcon,
} from "@heroicons/react/24/outline";
import Logo from "../../asset/logo.png"
import { IoMdArrowRoundUp } from "react-icons/io";
import { Link } from "react-router-dom";
import "./Navbar.css"
const navListMenuItems = [
  {
    title: "Case Studies",
    
  },
  {
    title: "Portfolio",
 
  },
  {
    title: "Blog",
   
  },
  {
    title: "Exhibition Center",
  
  },
  
];
const aboutListMenuItems = [
      {
        title: "About Us",
        btnClass:"subMenuText1",
        link:"/aboutus"
        
      },
      {
        title: "Vision",
        btnClass:"subMenuText1",
        link:"/mission-vision"
      },
      {
        title: "Management",
         btnClass:"subMenuText1",
         link:"/testing-page"
     
      },
      {
        title: "Infrastructure",
       btnClass:"subMenuText1"
      },
      {
        title: "Certificates",
      btnClass:"subMenuText1"
      },
    
  ];
  const productListMenuItems = [
    {
      title: "Components",
      btnClass:"subMenuText1"
      
    },
    {
      title: "Fabrication",
       btnClass:"subMenuText1"
   
    },
    {
      title: "Assembly",
     btnClass:"subMenuText1"
    },
    
    
  ];
  const clientListMenuItems = [
    {
        title: "Power Sector",
        btnClass:"subMenuText1"
        
      },
      {
        title: "Packaging Industry",
         btnClass:"subMenuText1"
     
      },
      {
        title: "Defence Sector",
       btnClass:"subMenuText1"
      },
      {
        title: "Dairy Industry",
      btnClass:"subMenuText1"
      },
    
  ];
  // const careerListMenuItems = [
  //   {
  //       title: "Job Openings",
  //       btnClass:"subMenuText1"
        
  //     },
  //     {
  //       title: "Job Applications",
  //        btnClass:"subMenuText1"
     
  //     },
    
  // ];
  const nestedMenuItems = [
   
    {
      title: "MANUFACTURING",
      link:'/manufacturing'
    },
    {
      title: "Quality",
      link:'/quality'
    },
    {
      title: "TESTING",
      link: "/testing",
    },
 
  ];
  function AboutNavListMenu({btnClass}) {
    const [isMenuOpen, setIsMenuOpen] = React.useState(false);
  const [openNestedMenu, setopenNestedMenu] = React.useState(false);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = React.useState(false);
  const renderItems = nestedMenuItems.map(({ title,link }, key) => (
    <Link to={link} key={key}>
    
      <MenuItem className="subMenuText1">{title}</MenuItem>
    </Link>
  ));
 
  return (
    <React.Fragment>
      <Menu
        open={isMenuOpen}
        handler={setIsMenuOpen}
        placement="bottom"
        allowHover={true}
      >
        <MenuHandler>
          <Typography as="div" variant="small" className="font-medium">
            <p
              className={btnClass}
              selected={isMenuOpen || isMobileMenuOpen}
              onClick={() => setIsMobileMenuOpen((cur) => !cur)}
            >
            Corporate Info
              <ChevronDownIcon
                strokeWidth={2.5}
                className={`hidden h-3 w-3 transition-transform lg:block ${
                  isMenuOpen ? "rotate-180" : ""
                }`}
              />
              <ChevronDownIcon
                strokeWidth={2.5}
                className={`block h-3 w-3 transition-transform lg:hidden ${
                  isMobileMenuOpen ? "rotate-180" : ""
                }`}
              />
            </p>
          </Typography>
        </MenuHandler>
        <MenuList style={{backgroundColor:"none !important"}} className="hidden mainMenuItem  bg-none text-center shadow-none border-none max-w-screen-xl  lg:block">
        <ul className="grid grid-cols-1   bg-none grid-flow-row gap-y-2 text-center  outline-none outline-0">
        <MenuItem className="subMenuText1">
          <Link to="/aboutus" >About Us</Link></MenuItem>
          <MenuItem className="subMenuText1">
          <Link to="/mission-vision" >MISSION & VISION</Link></MenuItem>
          <Menu
          className='grid grid-cols-1   bg-none grid-flow-row gap-y-2 text-center  outline-none outline-0'
            placement="right-start"
            allowHover
            offset={15}
            open={openNestedMenu}
            handler={setopenNestedMenu}
          >
            <MenuHandler >
              <MenuItem className="subMenuText1">
              INFRASTRUCTURE
                <ChevronUpIcon
                  strokeWidth={2.5}
                  className={`h-3.5 w-3.5 transition-transform ${
                    isMenuOpen ? "rotate-90" : ""
                  }`}
                />
              </MenuItem>
            </MenuHandler>
            <MenuList style={{backgroundColor:"none !important"}} className="hidden mainMenuItem  bg-none text-center shadow-none border-none max-w-screen-xl  lg:block">
            <ul className="grid grid-cols-1   bg-none grid-flow-row gap-y-2 text-center  outline-none outline-0">
              <Link to="/research">
              <MenuItem className="subMenuText1">DESIGN, RESEARCH <br/> & DEVELOPMENT</MenuItem>
              </Link>
              {renderItems}
              <Link to="/packing-forwarding">
              <MenuItem className="subMenuText1">PACKING <br/> & FORWARDING</MenuItem>
              </Link>
              </ul>
              </MenuList>
          </Menu>
         
          <MenuItem className="subMenuText1">
          <Link to="/certification" >Certification</Link></MenuItem>
           
          <MenuItem className="subMenuText1">
          <Link to="/code-of-conduct" >CODE OF CONDUCT</Link></MenuItem>
          </ul>
        </MenuList>
      </Menu>
      <div className="block lg:hidden">
        <Collapse open={isMobileMenuOpen}>
          <Menu
            placement="bottom"
            allowHover
            offset={6}
            open={openNestedMenu}
            handler={setopenNestedMenu}
          >
            <MenuHandler className="flex items-center justify-between">
              <MenuItem>
                Figma
                <ChevronUpIcon
                  strokeWidth={2.5}
                  className={`h-3.5 w-3.5 transition-transform ${
                    isMenuOpen ? "rotate-90" : ""
                  }`}
                />
              </MenuItem>
            </MenuHandler>
            <MenuList className="block rounded-xl lg:hidden">
              {renderItems}
            </MenuList>
          </Menu>
          <MenuItem className="subMenuText1">
          <Link to="/research" >
          DESIGN, RESEARCH
          & DEVELOPMENT
      </Link>
      </MenuItem>
          <MenuItem className="subMenuText1">TailwindCSS</MenuItem>
        </Collapse>
      </div>
    </React.Fragment>
  );
  }
function NavListMenu({titleItem,list,btnClass, scrollWithOffset}) {
  const [isMenuOpen, setIsMenuOpen] = React.useState(false);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = React.useState(false);
  const renderItems = list.map(
    ({ icon, title, description, btnClass, link }, key) => (
      <a href="#" key={key}>
        <MenuItem className={btnClass}>
        <div>
          <p className="">
            {link ? (
              <Link to={link} smooth scroll={el => scrollWithOffset(el)} className="">
                {title}
              </Link>
            ) : (
              title
            )}
          </p>
        </div>
        </MenuItem>
      </a>
    ),
  );
 
  return (
    <React.Fragment>
      <Menu
      style={{backgroundColor:"none !important"}}
        open={isMenuOpen}
        handler={setIsMenuOpen}
       className="bg-none shadow-none"
        allowHover={true}
      >
        <MenuHandler  className="bg-transparent shadow-none">
          <Typography>
            <p
              className={btnClass}
              selected={isMenuOpen || isMobileMenuOpen}
              onClick={() => setIsMobileMenuOpen((cur) => !cur)}
            >
              {titleItem}
              
            </p>
          </Typography>
        </MenuHandler>
        <MenuList style={{backgroundColor:"none !important"}} className="hidden mainMenuItem  bg-none text-center shadow-none border-none max-w-screen-xl  lg:block">
          <ul className="grid grid-cols-1   bg-none grid-flow-row gap-y-2 text-center  outline-none outline-0">
            {renderItems}
          </ul>
        </MenuList>
      </Menu>
      <div className="block lg:hidden">
        <Collapse open={isMobileMenuOpen}>{renderItems}</Collapse>
      </div>
    </React.Fragment>
  );
}
export function NavbarTwo() {
  const [openNav, setOpenNav] = React.useState(false);
 
  React.useEffect(() => {
    window.addEventListener(
      "resize",
      () => window.innerWidth >= 960 && setOpenNav(false),
    );
  }, []);
  const scrollWithOffset = (el) => {
    const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
    const yOffset = -80; 
    window.scrollTo({ top: yCoordinate + yOffset, behavior: 'smooth' }); 
}
 
  const navList = (
    <ul className="mb-0 mt-0 z-[999] flex flex-col font-[PoppinsRegular] text-black  lg:mb-0 lg:mt-0 lg:justify-center lg:flex-row lg:items-center ">
    
    
      
      <Typography
       
        className="menuText1"
      >
        <Link to="/products"  smooth scroll={el => scrollWithOffset(el)} className="">
         Home
         </Link>
      </Typography>
     
      <AboutNavListMenu  btnClass="menuText2"  />
      

      <NavListMenu  btnClass="menuText1" titleItem="Products" list={productListMenuItems} />
      <NavListMenu   btnClass="menuText2" titleItem="Clientele" list={clientListMenuItems} />
      {/* <NavListMenu btnClass="menuText1" titleItem="Career" list={careerListMenuItems} /> */}

      <Typography className="menuText1">
        <Link to="/career" smooth scroll={el => scrollWithOffset(el)}>
          Career
        </Link>
      </Typography>

      <Typography

        className="menuText2">

        <Link to="/contact" smooth scroll={el => scrollWithOffset(el)} className="">
         Contact Us
        </Link>

      </Typography>

      <Typography

        className="mainBtn mt-4 ">
        <Link to="#contact" smooth scroll={el => scrollWithOffset(el)} className="">
        Request a Quote
        </Link>

      </Typography>
    </ul>
  );
 
  return (
    <Navbar className={` fixed    w-[100vw !important] navBarBg  shadow-none max-w-full    z-[999]  rounded-none  py-2 px-2 lg:px-5 xl:px-24 lg:py-3`}>
      <div className="  grid grid-cols-2 lg:grid-cols-4 justify-between items-center text-blue-gray-900">
       <Link className="col-span-1" to="/">
        <img className=" navLogo " src={Logo} alt="" />
       </Link>
        <div className="hidden lg:block  col-span-3 ">{navList}</div>
        
       <div className="col-span-1 flex items-center justify-end " >
      
     
        <IconButton
              variant="text"
              className="lg:ml-auto ml-2 h-6 w-6 col-span-4 text-[#bfbfbf] hover:bg-transparent focus:bg-transparent active:bg-transparent lg:hidden"
              ripple={false}
              onClick={() => setOpenNav(!openNav)}
            >
              {openNav ? (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  className="h-6 w-6"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  strokeWidth={2}
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              ) : (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-6 w-6"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth={2}
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M4 6h16M4 12h16M4 18h16"
                  />
                </svg>
              )}
            </IconButton>
            </div>
      </div>
      <MobileNav open={openNav}>
        <div className="container  mx-0 p-0">
          {navList}
         
        </div>
      </MobileNav>
    </Navbar>
  );
}