import React from 'react'
import "./about.css"

import certificationImg from '../../asset/certification.png';


const Certification = () => {
  return (
    <div className='bg-pattern'>
        <div className="container items-center mx-auto px-4 lg:py-10 py-5 ">
            <h1 className="text-4xl g:text-[2.2rem] bg-gradient-to-r from-[#99821d] via-[#ccc595] to-[#9d8944] text-transparent bg-clip-text uppercase lg:leading-[2.5rem] font-[GilroyBold] font-bold text-center mb-8">
            CERTIFICATES
            </h1>
                <p className="mb-4 text-center">
                We have always been a firm believer of possessing all the necessary certifications Required. This page contains all the certifications that our organization Hydrodyne Teikoku (India) Pvt. Ltd. currently possess.
                </p>
            
            <div className="lg:flex lg:col-span-1 lg:flex-row items-start">
                <div className="lg:w-1/2 pr-8 mb-8 lg:mb-0">
                <p className="text-black my-1 text-[2rem] font-[GilroyMedium] lg:my-6 py-5 aboutContent ">
                Certificate of appreciation from Government of India, Ministry of Finance. Completed prompt filing of returns and payment of goods and service tax during financial year 2022-23
                </p>
                <ul className="list-disc list-inside space-y-2">
                    <li><strong>	ISO 9001 : 2015</strong></li>
                    <li><strong>	ISO 14001 : 2015</strong> </li>
                </ul>
                </div>

                
                    
                <div className="lg:col-span-1 flex items-center justify-center lg:px-[40px] py-5 ">
                {/*  Images */}
                <img className='2xl:w-[550px]' src={certificationImg} alt="Image 1"/>
            
                </div>

                

            </div>
        </div>
    </div>
    
  )
}

export default Certification