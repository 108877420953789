import React from 'react';
import "./about.css"
import { ArrowRightIcon } from '@heroicons/react/24/outline'

import image from '../../asset/aboutInfo.png';

const AboutInfo = () => {
  return (
    <div className='bg-pattern'>
      <div className="lg:grid block py-5 px-6  lg:px-6 aboutInfoContainer grid-cols-2 items-center justify-center gap-24 lg:py-24">
      {/* Main Body */}


          <div className="lg:col-span-1 flex items-center justify-center px-[40px]">
          {/* Hexagonal Images */}
            
            <img className='2xl:w-[450px]' src={image} alt="Image 1"/>
          </div>


          {/* Info Section */}
          <div className="lg:col-span-1 lg:px-[40px] py-5" >
              <h1 className="lg:text-[2.2rem] text-[1.8rem] bg-gradient-to-r from-[#99821d] via-[#ccc595] to-[#9d8944] inline-block text-transparent bg-clip-text uppercase lg:leading-[2.5rem] font-[GilroyBold]">About Us</h1>
              <p className="text-black my-1 text-[1.4rem] font-[GilroyMedium] lg:my-6 aboutContent  ">
              <strong>HARDCOATS (INDIA) PVT. LTD.</strong>  has established itself as a leading manufacturer and supplier of high-precision components and assemblies since 1990. With a commitment to quality and innovation, we cater to a diverse range of industries, including the Power Sector, Energy Sector, Packaging Industry, Fusion Sector, Defense Sector, and Oil & Gas Industries. Our extensive experience and expertise allow us to meet the unique needs of our global clientele, providing tailored solutions that enhance efficiency and performance. As a trusted partner in these critical industries, we are dedicated to delivering superior products and services that drive success for our customers worldwide
              </p>
              <p className="text-black my-1 text-[1.4rem] font-[GilroyMedium] lg:my-6 aboutContent ">
              Our manufacturing unit is located in Thane. We are well equipped with high precision conventional and CNC - VMC machineries, knowledgeable technical team, highly skilled workforce and experienced Quality - Control Assurance and planning Department. Despite the small size of our company, with teamwork and dynamic leadership, we are in position to execute any high precision engineering products at exacting standards required in Energy, Power and other industries.              </p>
              <p className="text-black text-[1.4rem] my-1 font-[GilroyMedium] lg:my-6 aboutContent ">
              We are a one-stop solution provider to our clients, with a capability to manufacture a diverse range of products across sectors. Key products manufactured by us include precision components, sub- assemblies and assemblies in special grade alloy materials i.e. Stainless Steel grades, Incoloy, Titanium, Ceramic, Tungsten Carbide, Silicon Carbide, Carbon/ Graphite grades etc. to the best of accuracy as well as satisfaction to esteemed users. Irrespective of size we can manufacture Small components as well as Big components.              </p>
              <button className='bg-gradient-to-r from-[#99821d]  to-[#9d8944] text-white px-6 flex items-center justify-center gap-4 py-3 mt-3 rounded-lg text-[1.1rem]'>Know More <ArrowRightIcon className='w-[25px]' /></button>
            </div>


            

      </div>
    </div>
  );
};

export default AboutInfo;
