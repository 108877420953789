import React from 'react';
import "./about.css"
import { ArrowRightIcon } from '@heroicons/react/24/outline'

import image from '../../asset/testing.png';


const Testing = () => {
  return (
    <div className='bg-pattern'>
      <div className="lg:grid block py-5 px-6  lg:px-6 aboutInfoContainer grid-cols-2 items-center justify-center gap-24 lg:py-24">
      {/* Main Body */}
            
          
            {/* Info Section */}
            <div className="lg:col-span-1 px-[40px]" >
          <h1 className="lg:text-[2.2rem] text-[1.8rem] bg-gradient-to-r from-[#99821d] via-[#ccc595] to-[#9d8944] inline-block text-transparent bg-clip-text uppercase lg:leading-[2.5rem] font-[GilroyBold]">Testing</h1> 
          <p className="text-black my-1 text-[1.4rem] font-[GilroyMedium] lg:my-6 aboutContent ">
          At HARDCOATS (INDIA) PVT. LTD., our comprehensive testing facility is integral to ensuring the highest quality and reliability of our precision components. Equipped with advanced testing equipment, we offer a range of specialized services:
          </p>
          <ul className=" text-black list-disc list-inside space-y-2">
            <li><strong>Helium Leak Detector Testing Facility:</strong> Ensures the integrity of sealed components by detecting even the smallest leaks.</li>
            <li><strong>Pneumatic Pressure Testing Facility:</strong> Validates the performance and safety of components under high-pressure conditions.</li>
            <li><strong>Hydraulic Pressure Testing Facility:</strong> Assesses the strength and durability of hydraulic systems and parts.</li>
            <li><strong>Spring Load Rate Testing Machine:</strong> Measures the load capacity and performance characteristics of springs.</li>
            <li><strong>Heat Treatment Furnace:</strong> Facilitates precise thermal processes to enhance material properties and performance.</li>
            <li><strong>Full-fledged NDT Facilities:</strong> Our non-destructive testing capabilities include Ultrasonic, Magnetic Particle, and Dye Penetrant methods to identify internal and surface defects without compromising the integrity of the components.</li>
            <li><strong>Assembly Room:</strong> A dedicated space for final assembly and quality checks, ensuring all products meet rigorous standards before delivery.</li>
          </ul>
          <p className="text-black my-1 text-[1.4rem] font-[GilroyMedium] lg:my-6 aboutContent ">
          Our commitment to excellence is reflected in our state-of-the-art testing facilities, which support our mission to deliver superior products that consistently meet our clients' exact specifications.
          </p>
          <button className='bg-gradient-to-r from-[#99821d]  to-[#9d8944] text-white px-6 flex items-center justify-center gap-4 py-3 mt-3 rounded-lg text-[1.1rem]'>Know More <ArrowRightIcon className='w-[25px]' /></button>

        </div>

        <div className="lg:col-span-1 flex items-center justify-center lg:mt-0 mt-4 px-[40px] py-5">
            {/*  Images */}
              <img className='2xl:w-[450px]' src={image} alt="Image 1"/>
             
            </div>

      </div>
    </div>
  );
};

export default Testing;
