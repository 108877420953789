import React, { useRef, useCallback } from 'react';
import { Swiper, SwiperSlide } from "swiper/react";
import { IoMdArrowDropleftCircle, IoMdArrowDroprightCircle } from "react-icons/io";
import SwiperCore, { Navigation } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
import IndustryCard from './IndustryCard';
import PowerImage from "../../asset/power.png";
import PackagingImage from "../../asset/packaging.png";
import DairyImage from "../../asset/dairy.png";
import EnergyImage from "../../asset/energy.png";
import DefenseImage from "../../asset/defense.png";
import AerospaceImage from "../../asset/aerospace.png";
import FusionImage from "../../asset/fusion.png";
import OilImage from "../../asset/oil.png";

const Industries = () => {
  const sliderRef = useRef(null);

  const handlePrev = useCallback(() => {
    if (!sliderRef.current) return;
    sliderRef.current.swiper.slidePrev();
  }, []);

  const handleNext = useCallback(() => {
    if (!sliderRef.current) return;
    sliderRef.current.swiper.slideNext();
  }, []);

  const industries = [
    { image: PowerImage, title: "Power Sector" },
    { image: PackagingImage, title: "Packaging Sector" },
    { image: DairyImage, title: "Dairy and Pharma Industry" },
    { image: EnergyImage, title: "Energy Sector" },
    { image: DefenseImage, title: "Defense Sector" },
    { image: AerospaceImage, title: "Aerospace Sector" },
    { image: FusionImage, title: "Fusion Energy" },
    { image: OilImage, title: "Oil and Gas Industry" }
  ];

  return (
    <div className='industriesContainer'>
      <div className='text-center'>
        <h3 className='lg:text-[1.1rem] text-[.8rem] text-gray-600'>Industries We Have Worked With</h3>
        <h2 className='lg:text-[3.5rem] text-[2.2rem] bg-gradient-to-r from-[#99821d] via-[#ccc595] to-[#9d8944] inline-block text-transparent bg-clip-text uppercase font-[700]'>
          Our Work
        </h2>
      </div>
      
      <div className='flex items-center justify-center mt-6'>
        <IoMdArrowDropleftCircle onClick={handlePrev} className="text-[#99821d] text-[2.5rem] cursor-pointer mb-16" />
        <Swiper
          ref={sliderRef}
          spaceBetween={20}
          slidesPerView={6} // Show 6 slides at a time
          loop={true}
          breakpoints={{
            576: { slidesPerView: 1 },
            768: { slidesPerView: 3 },
            1024: { slidesPerView: 5 },
            1440: { slidesPerView: 6 }, // Display 6 cards on larger screens
          }}
          navigation={false}
          modules={[Navigation]}
        >
          {industries.map((industry, index) => (
            <SwiperSlide key={index}>
              <IndustryCard image={industry.image} title={industry.title} />
            </SwiperSlide>
          ))}
        </Swiper>
        <IoMdArrowDroprightCircle onClick={handleNext} className="text-[#99821d] text-[2.5rem] cursor-pointer mb-16" />
      </div>
    </div>
  );
};

export default Industries;
