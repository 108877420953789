import React from 'react'
import { NavbarTwo } from '../components/Layout/Navbar';
import Footer from '../components/Layout/Footer';
import Testing from '../components/About/Testing';



const TestingPage = () => {
  return (
    <div>
        <NavbarTwo />
        <div className='2xl:pt-[120px] pt-[80px] xl:pt-[110px] lg:pt-[100px]'>
        <Testing />
        <Footer />
        </div>
    </div>
  )
}

export default TestingPage;