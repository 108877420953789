import React from 'react'

import { MdLocationOn } from "react-icons/md";
import { FaPhoneAlt } from "react-icons/fa";
import { IoIosMail } from "react-icons/io";

const ContactForm = () => {
  return (
    
    <div className="  px-4 py-16">
            <div className="grid grid-cols-1 lg:grid-cols-2 gap-8">
              <div className="bg-white p-6 rounded-lg shadow-md">
                <h2 className="text-2xl font-semibold mb-4">Get in Touch</h2>
                <form onSubmit={(e) => e.preventDefault()} className="space-y-4">
                    <div className='grid gap-3 grid-cols-1 lg:grid-cols-2 grid-flow-row' >
                        <div className=''>
                            <label htmlFor="name"  className="block text-lg  font-[GilroyMedium] text-black">Name</label>
                            <input id="name"  className='border-[1px] border-gray-400 my-2 w-full outline-none text-black placeholder:text-gray-700 py-3 px-3 rounded-lg ' placeholder="Your Name" />
                        </div>
                        <div>
                            <label htmlFor="email" className="block text-lg  font-[GilroyMedium] text-black">Email</label>
                            <input id="email" className='border-[1px] border-gray-400 my-2 w-full outline-none text-black placeholder:text-gray-700 py-3 px-3 rounded-lg' type="email" placeholder="your@email.com" />
                        </div>
                    </div>
                    <div className='grid gap-3 grid-cols-1 lg:grid-cols-2 grid-flow-row'>
                        <div className=''>
                            <label htmlFor="phone" className="block text-lg  font-[GilroyMedium] text-black">Company Name</label>
                            <input id="name"  className='border-[1px] border-gray-400 my-2 w-full outline-none text-black placeholder:text-gray-700 py-3 px-3 rounded-lg' type="tel" placeholder="Company Name" />
                        </div>
                        <div>
                            <label htmlFor="phone" className="block text-lg  font-[GilroyMedium] text-black">Phone</label>
                            <input id="phone"  className='border-[1px] border-gray-400 my-2 w-full outline-none text-black placeholder:text-gray-700 py-3 px-3 rounded-lg' type="tel" placeholder="+1 (555) 000-0000" />
                        </div>
                    </div>

                    <div>
                        <label htmlFor="address" className="block text-lg  font-[GilroyMedium] text-black ">Message</label>
                        <textarea id="address"  className='border-[1px] border-gray-400 my-2 w-full outline-none text-black placeholder:text-gray-700 py-3 px-3 rounded-lg' placeholder="Your Message" />
                    </div>

                    <div className='flex items-center justify-center'>
                        <button type="submit" className=" w-[150px] text-white bg-gradient-to-r from-[#99821d] via-[#ccc595] to-[#9d8944]  py-3 px-3 rounded-lg">Submit</button>
                    </div>
                </form>
              </div>
              <div className="  rounded-lg shadow-md gap-8">
              <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15064.691587857104!2d72.8885266!3d19.274846!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be7b07cbe02ac6f%3A0xd2f8d64f23df4c03!2sHardcoats%20(India)%20Pvt%20Ltd!5e0!3m2!1sen!2sin!4v1727779445414!5m2!1sen!2sin" className='w-full lg:h-full h-[300px] border-none rounded-lg' allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
              </div>
            </div>
          </div>

  )
}

export default ContactForm