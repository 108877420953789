import React from 'react'
import { NavbarTwo } from '../components/Layout/Navbar'
import Certification from '../components/About/Certification'
import Footer from '../components/Layout/Footer'

const CertificationPage = () => {
  return (
    <div>
        <NavbarTwo />
        <div className='2xl:pt-[120px] pt-[80px] xl:pt-[110px] lg:pt-[100px]'>
        <Certification />
        <Footer/>
        </div>
    </div>
  )
}

export default CertificationPage