import React from 'react'
import { NavbarTwo } from '../components/Layout/Navbar'
import Footer from '../components/Layout/Footer'
import CareerInfo from '../components/Career/CareerInfo'
import ContactBreadcrub from '../components/Career/CareerBreadcrub'


const ContactPage = () => {
  return (
    <div>
        <NavbarTwo />
        <div className='2xl:pt-[120px] pt-[80px] xl:pt-[110px] lg:pt-[100px]'>
        <ContactBreadcrub/>    
        <CareerInfo/>
        <Footer/>
        </div>
    </div>
  )
}

export default ContactPage;