import React from 'react'
import CountUp from 'react-countup';

const Numbers = () => {
  return (
    <div className='bg-black numbersMainBox'>
       
        <div className='grid grid-flow-col grid-cols-2' >
            <div className='text-center px-2 lg:border-r-[5px] border-r-[3px] border-[#99821d]'>
          <p className='lg:text-[3rem] text-[1rem] bg-gradient-to-r from-[#99821d] via-[#ccc595] to-[#9d8944] inline-block text-transparent bg-clip-text font-[800]'> <CountUp  duration={5}   end={35} /> +</p> 
               
                <p className='lg:text-[1.7rem] text-[.7rem] text-white font-[800]'>Projects Completed</p>
            </div>
            {/* <div className='text-center px-2 lg:border-r-[5px] border-r-[3px] border-[#99821d]'>
            <p className='lg:text-[3rem] text-[1rem] bg-gradient-to-r from-[#99821d] via-[#ccc595] to-[#9d8944] inline-block text-transparent bg-clip-text font-[800]'> <CountUp  duration={2}   end={1435} /> +</p> 
                <p className='lg:text-[1.7rem] text-[.7rem] text-white font-[800]'>Work Employed</p>
            </div>
            <div className='text-center px-2 lg:border-r-[5px] border-r-[3px] border-[#99821d]'>
            <p className='lg:text-[3rem] text-[1rem] bg-gradient-to-r from-[#99821d] via-[#ccc595] to-[#9d8944] inline-block text-transparent bg-clip-text font-[800]'> <CountUp  duration={2}   end={750} /> +</p> 
                <p className='lg:text-[1.7rem] text-[.7rem] text-white font-[800]'>Work Facilities</p>
            </div> */}
            <div className='text-center px-2'>
            <p className='lg:text-[3rem] text-[1rem] bg-gradient-to-r from-[#99821d] via-[#ccc595] to-[#9d8944] inline-block text-transparent bg-clip-text font-[800]'> <CountUp  duration={5}   end={26} /> +</p> 
                <p className='lg:text-[1.7rem] text-[.7rem] text-white font-[800]'>Winning Awards</p>
            </div>
        </div>
    </div>
  )
}

export default Numbers