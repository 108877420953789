import React, { useCallback, useRef } from 'react'
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, {
  Navigation,
  Pagination,
  Scrollbar,
  A11y,
  Autoplay,
} from "swiper/modules";
// Import Swiper styles
import "swiper/css";
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import { ArrowRightIcon } from '@heroicons/react/24/outline';
import QuoteImage from "../../asset/quote.png"
import { IoMdArrowDropleftCircle } from "react-icons/io";
import { IoMdArrowDroprightCircle } from "react-icons/io";
import "./Home.css"
const Testimonial = () => {
  const sliderRef = useRef(null);

  const handlePrev = useCallback(() => {
    if (!sliderRef.current) return;
    sliderRef.current.swiper.slidePrev();
  }, []);

  const handleNext = useCallback(() => {
    if (!sliderRef.current) return;
    sliderRef.current.swiper.slideNext();
  }, []);
  return (
    <div className='bg-black lg:flex flex-row gap-8 items-center 2xl:gap-24 justify-start testimonialMainBox' >
      <div>
        <p className='text-gray-200 text-center lg:text-left text-[1.1rem]'>What People Say's About Us</p>
         
        <h4 className='lg:text-[3.3rem] text-center lg:text-left text-[2.2rem]  bg-gradient-to-r from-[#99821d] via-[#ccc595] to-[#9d8944] inline-block text-transparent bg-clip-text  mt-2 uppercase lg:leading-[3.7rem] font-[700]'>Client's Testimonials</h4>
        <div className="page-indicator flex items-center justify-center lg:justify-start gap-1  mt-3 ">
          <IoMdArrowDropleftCircle onClick={handlePrev} className="arrowLeft  text-[#99821d] text-[2.5rem]"  />
          
          <IoMdArrowDroprightCircle onClick={handleNext} className="arrowRight text-[#99821d] text-[2.5rem]"  />
        </div>
        </div>
        <div className='overflow-hidden'>
        <Swiper
        ref={sliderRef}
          spaceBetween={0}
          className="mt-3 lg:mt-0 items-center justify-center"
         
          breakpoints={{
        576: {
          width: 576,
          slidesPerView: 1,
        },
        780: {
          width: 780,
          slidesPerView: 2,
        },
        1100: {
          width: 1100,
          slidesPerView: 2.5,
        },
         1440:{
          width:1440,
          slidesPerView:2.3
        },
        1800:{
          width:1800,
          slidesPerView:2.3
        }
      }}
     
          loop={true}
          
         
          modules={[Navigation]}
          onSlideChange={() => console.log("slide change")}
          onSwiper={(swiper) => console.log(swiper)}
        >
             <SwiperSlide>
          <div className="singleTestimonialItem  bg-white  rounded-lg mx-4  shadow-lg relitve px-8 py-8">
            <img className='w-[70px]' src={QuoteImage} />
            <div>
                <h3 className='font-[600] text-[1.4rem]'>Aniket Jha</h3>
                <p className='font-[500] text-[1.2rem]'>CEO and Founder</p>
            </div>
            <p className='my-3'>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. </p>
          </div>
            </SwiperSlide>
            <SwiperSlide>
          <div className="singleTestimonialItem  bg-white  rounded-lg mx-4  shadow-lg relitve px-8 py-8">
            <img className='w-[70px]' src={QuoteImage} />
            <div>
                <h3 className='font-[600] text-[1.4rem]'>Aniket 2  Jha</h3>
                <p className='font-[500] text-[1.2rem]'>CEO and Founder</p>
            </div>
            <p className='my-3'>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. </p>
          </div>
            </SwiperSlide>
            <SwiperSlide>
          <div className="singleTestimonialItem  bg-white  rounded-lg mx-4  shadow-lg relitve px-8 py-8">
            <img className='w-[70px]' src={QuoteImage} />
            <div>
                <h3 className='font-[600] text-[1.4rem]'>Aniket 3 Jha</h3>
                <p className='font-[500] text-[1.2rem]'>CEO and Founder</p>
            </div>
            <p className='my-3'>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. </p>
          </div>
            </SwiperSlide>
            <SwiperSlide>
          <div className="singleTestimonialItem  bg-white  rounded-lg mx-4  shadow-lg relitve px-8 py-8">
            <img className='w-[70px]' src={QuoteImage} />
            <div>
                <h3 className='font-[600] text-[1.4rem]'>Aniket Jha</h3>
                <p className='font-[500] text-[1.2rem]'>CEO and Founder</p>
            </div>
            <p className='my-3'>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. </p>
          </div>
            </SwiperSlide>
             </Swiper>
        </div>
        
    </div>
  )
}

export default Testimonial