import React from 'react'
import Logo from "../../asset/logo0.png"
import { IoMdMail } from "react-icons/io";
import { HiLocationMarker } from "react-icons/hi";
import { IoCall } from "react-icons/io5";
const Footer = () => {
  return (
    <div>
    <div className='bg-black block lg:flex items-center justify-center flex-col py-8 px-12'>
        <img className='w-[500px]' src={Logo} />
        <div>
            <p className='lg:text-center mt-3 mb-8 text-white text-[.9rem] lg:text-[1.2rem]'>industroz Industry has very good strength in innovative technology <br /> and tools with over 35 years of experience.</p>
        </div>
        <div  >
            <p className='lg:text-center text-white my-3 text-[.6rem] lg:text-[.8rem] flex items-center justify-center gap-3'><HiLocationMarker className='lg:text-[2.5rem] text-[3rem] text-[#99821d]' />Survey No 16/1, Laxmi Baug N.H-8 Opp Dara's dhaba, <br className='hidden lg:block' /> Kashimira Mira Road (E),Thane 401107, India.</p>
           
        </div>
        <div className='flex flex-col lg:flex-row items-start lg:items-center my-6 lg:justify-center gap-4 lg:gap-12'>
        <p className='lg:text-center text-[.8rem] lg:text-[1rem] text-white flex items-center justify-center gap-3'><IoMdMail className='text-[1.4rem] text-[#99821d]' />sales@hardcoatsindia.com</p>
        <p className='lg:text-center text-[.8rem] lg:text-[1rem] text-white flex items-center justify-center gap-3'><IoCall className='text-[1.4rem] text-[#99821d]' />(+91) 8655016069 | (+91) 8655036068</p>
        </div>

    </div>
    <div className='bg-gray-900 text-white text-[.9rem] lg:text-[1.1rem] py-4 px-12 text-center'>
    <p>Copyright © 2024 Hardcoats India Pvt Ltd</p>
    </div>
    </div>
  )
}

export default Footer