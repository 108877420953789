import React from 'react'
import Image from '../../asset/energy.png'
import { MdLocationOn } from "react-icons/md";
import { FaPhoneAlt } from "react-icons/fa";
import { IoIosMail } from "react-icons/io";

const ContactInfo = () => {

  return (

    <div className="w-[100%]  py-8">
      {/* Header */}
      


      {/* Contact Info */}
          <div className='grid grid-cols-1 lg:grid-cols-3 gap-4 p-6'>
              <div className='text-center'>
                <div className=' p-6 rounded-lg shadow-lg hover:shadow-2xl group hover:border-[1px] hover:border-[black]   '>
                  <div className=' flex align-items-center justify-center '>
                  <MdLocationOn className="text-[3.5rem]  rounded-full px-2 py-2 group-hover:bg-gradient-to-r group-hover:text-white from-[#99821d] via-[#ccc595] to-[#9d8944]" />
                  </div>
                  <h2 className='lg:text-[1.5rem] text-[1.2rem] font-[700]'>Office</h2>
                  <p>E-15 Industrial Estate Kota Rajasthan</p>
                </div>
              </div>

              <div className='text-center'>
                <div className=' p-6  rounded-lg shadow-lg hover:shadow-2xl group hover:border-[1px] hover:border-[black]'>
                  <div className=' flex align-items-center justify-center '>
                    <FaPhoneAlt className="text-[3.5rem]  rounded-full px-2 py-2 group-hover:bg-gradient-to-r group-hover:text-white from-[#99821d] via-[#ccc595] to-[#9d8944]"/>
                  </div>
                  <h2 className='lg:text-[1.5rem] text-[1.2rem]  font-[700]'>Phone</h2>
                  <p>+91 9983 810 039</p>
                </div>
              </div>

              <div className='text-center'>
                <div className=' p-6 rounded-lg shadow-lg hover:shadow-2xl group hover:border-[1px] hover:border-[black]'>
                <div className=' flex align-items-center justify-center '>
                    <IoIosMail className="text-[3.5rem]  rounded-full px-2 py-2 group-hover:bg-gradient-to-r group-hover:text-white from-[#99821d] via-[#ccc595] to-[#9d8944]"/>
                  </div>
                  <h2 className='lg:text-[1.5rem] text-[1.2rem]  font-[700] '>Email</h2>
                  <p>hardcort@hardcoatsindia.com</p>
                </div>
              </div>
          </div>


          

    </div>
    
    
  )
}

export default ContactInfo