import React from 'react'
import "./about.css"
import { ArrowRightIcon } from '@heroicons/react/24/outline'
import VisionMission from '../../asset/VisionMission.png';

const Vision = () => {
    return (
        <div className='bg-pattern'>
      <div className="lg:grid block px-6  lg:px-6 aboutInfoContainer grid-cols-2 items-center justify-center gap-24 lg:py-24 py-5">
      {/* Main Body */}
      <div className="lg:col-span-1 flex items-center justify-center  px-[40px]">
            {/*  Images */}
              <img className='2xl:w-[500px]' src={VisionMission} alt="Image 1"/>
             
            </div>
    
              
              {/* Info Section */}
              <div className="lg:col-span-1 px-[40px] pt-5 lg:pt-0" >
              <h1 className="lg:text-[2.2rem] text-[1.8rem] bg-gradient-to-r from-[#99821d] via-[#ccc595] to-[#9d8944] inline-block text-transparent bg-clip-text uppercase lg:leading-[2.5rem] font-[GilroyBold]">MISSION AND VISION</h1>
              <p className="text-black my-1 text-[1.4rem] font-[GilroyMedium] lg:my-6 aboutContent ">
                  At Hardcoats, we are dedicated to consistently delivering high-precision engineering solutions with unwavering quality, meeting exacting standards through innovative technology, a skilled workforce, and a commitment to excellence. Our aim is to be the trusted partner of choice, providing tailored solutions that drive efficiency and reliability for our clients.</p>
                  <p className="text-black my-1 text-[1.4rem] font-[GilroyMedium] lg:my-6 aboutContent ">
                  We envision being the forefront provider of precision components and assemblies, setting benchmarks in quality and reliability. Our vision is to be recognized as a symbol of precision, integrity, and innovation in every industry we serve.”</p>
                  
                  <button className='bg-gradient-to-r from-[#99821d]  to-[#9d8944] text-white px-6 flex items-center justify-center gap-4 py-3 mt-3 rounded-lg text-[1.1rem]'>Know More <ArrowRightIcon className='w-[25px]' /></button>
                </div>
    
          </div>
        </div>
      );
}

export default Vision;