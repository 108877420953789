import React from 'react'
import "./about.css"
import { ArrowRightIcon } from '@heroicons/react/24/outline'
import ManufacturingImg from '../../asset/Manufacturing.png';

const Manufacturing = () => {
  return (
    <div className='bg-pattern'>
      <div className="lg:grid block py-5 px-6  lg:px-6 aboutInfoContainer grid-cols-2 items-center justify-center gap-24 lg:py-24">
      {/* Main Body */}
      <div className="lg:col-span-1 flex items-center justify-center px-[40px] py-5">
      {/*  Images */}                
                  <img className='2xl:w-[500px]' src={ManufacturingImg} alt="Image 1"/>
              </div>
    
              
              {/* Info Section */}
              <div className="lg:col-span-1 px-[40px] py-5" >
              <h1 className="lg:text-[2.2rem] text-[1.8rem] bg-gradient-to-r from-[#99821d] via-[#ccc595] to-[#9d8944] inline-block text-transparent bg-clip-text uppercase lg:leading-[2.5rem] font-[GilroyBold]">Manufacturing</h1>
              <p className="text-black my-1 text-[1.4rem] font-[GilroyMedium] lg:my-6 aboutContent py-3">
                   <strong>HARDCOATS   (INDIA)   PVT.   LTD.'s</strong> manufacturing unit, located in Thane, is equipped with state-of-the-art high- precision conventional and CNC-VMC machinery. Our facility is staffed by a knowledgeable technical team and a highly skilled workforce, all dedicated to maintaining rigorous quality control standards. Our dynamic leadership and collaborative teamwork empower us to execute complex engineering projects that meet the exacting standards of the Energy, Power, and other critical industries.</p>
                   <p className="text-black my-1 text-[1.4rem] font-[GilroyMedium] lg:my-6 aboutContent ">
                   We specialize in manufacturing a diverse range of precision components, sub-assemblies, and assemblies from special grade alloys, ensuring optimal performance and reliability for our global clientele. Our commitment to quality and innovation drives us to continually enhance our manufacturing capabilities, making us a trusted one- stop solution provider for high- precision engineering needs.</p>
                  
                  <button className='bg-gradient-to-r from-[#99821d]  to-[#9d8944] text-white px-6 flex items-center justify-center gap-4 py-3 mt-3 rounded-lg text-[1.1rem]'>Know More <ArrowRightIcon className='w-[25px]' /></button>
                </div>
    
          </div>
        </div>
  )
}

export default Manufacturing;